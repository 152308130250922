import React from 'react';

import Layout from '../components/layout';
import ContactPage from '../ContactPage/ContactPage';

export default () => (
  <Layout>
    <ContactPage />
  </Layout>
);
