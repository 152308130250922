import React from 'react';
import styled from 'styled-components';

import { PrimaryHeading } from '../shared/typography';
import Nav from '../shared/Navbar/Navbar';
import palette from '../shared/palette';

const ContactPage = props => {
  return (
    <Page>
      <Nav />
      <PrimaryHeading style={{ color: `${palette.light1}` }}>
        Have a burning question or comment...?
      </PrimaryHeading>
      <h1>hello</h1>
    </Page>
  );
};

export default ContactPage;

const Page = styled.div`
  padding: 3rem;
  padding-top: 18rem;
  background-color: ${palette.dark1};
`;
